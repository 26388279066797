.aButton {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: black;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin: 0;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
  text-decoration: none;
}
