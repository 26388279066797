@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/services';
@import 'styles/generics';

input[type='text'] {
  padding: 10px;
  border: 0;
}

button {
  color: $black;
}

font[face='Playfair'] {
  font-family: $Playfair !important;
}

font[face='Raleway'] {
  font-family: $Raleway !important;
}

.App {
  font-family: $Raleway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  padding-bottom: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.home .container {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.userpage .container {
  /*padding-left: 16px !important;
  padding-right: 16px !important;*/
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

header {
  box-shadow: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
}

code {
  font-family: $generic-font-family-2;
}

.bgEMERGENZA {
  background-color: $emergenza;
}

.txtEMERGENZA {
  color: $emergenza;
}

.bgBianco {
  background-color: $white;
}

.bgDOMICILIARE {
  background-color: $domiciliare;
}

.txtDOMICILIARE {
  color: $domiciliare;
}

.bgFAMILIARE {
  background-color: $familiare;
}

.bgNero {
  background-color: $black;
  color: $white;
}

.txtFAMILIARE {
  color: $white;
}

.bullet {
  font-size: 300%;
  position: relative;
  top: 12px;
}

.bullet.txtFAMILIARE {
  color: $familiare;
}

img,
svg {
  max-width: 100%;
}

.clickable {
  cursor: pointer;
}

.separatorContainer {
  text-align: center;
  font-size: 0;
}

.buttonLink {
  cursor: pointer;
  background-color: $btngreybg;
  color: $white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 25px;
  font-weight: bold;
  box-shadow: 2px 2px 4px $basaltGray !important;
}

.buttonLink.with-shadow {
  width: 70%;
  display: block;
  margin: auto;
  box-shadow: 2px 2px 4px $basaltGray, 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.buttonLink.bg-green {
  color: $white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 25px;
  font-weight: bold;
}

.buttonLink.bg-green:disabled {
  opacity: 0.2;
}

.cart .buttonLink.bg-green {
  width: 70%;
  display: block;
  margin: auto;
}

.buttonLink.bg-red {
  background-color: $red;
  color: $white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 25px;
  font-weight: bold;
}

.buttonLink.bg-sunset-orange {
  background-color: $sunset-orange;
  color: $white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 25px;
  font-weight: bold;
}

.buttonBlue {
  background-color: $btnbluebg;
  color: $white;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 10px 15px;
  border: none;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.needlesButtons {
  margin-top: 0 !important;

  .buttonBlue {
    background-color: $btnbluebg;
    color: $white;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 10px 25px;
    border: none;
    display: block;
    text-decoration: none;
    cursor: pointer;
  }
}

.EMERGENZA.needlesButtons .buttonBlue {
  background-color: $black;
  color: $white;
}

.FAMILIARE.needlesButtons .buttonBlue {
  background-color: $black;
  color: $white;
}

button.lnkButton {
  text-transform: uppercase;
  font-weight: bold;
  color: $black;

  svg {
    color: #38809a;
  }
}

button.lnkBack {
  svg {
    font-size: 56px !important;
    color: $basaltGray;
  }
}

a.lnkForward {
  color: $white;
  font-size: 1em;
  position: relative;
  top: 22px;
  background-color: $turqisa;
}

a.lnkForward.disabled {
  opacity: 0.4;
}

a.selectAll {
  color: $white;
  font-size: 1em;
  background-color: $turqisa;
}

.needlesButtons {
  .btnText {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
    border: 0;
    cursor: pointer;
    font-family: $Raleway;
    font-weight: 700;
    font-size: 0.95em;
    text-transform: uppercase;
    box-shadow: 2px 2px 8px $silverGray !important;
  }
}

.boxCeleste.needlesButtons button.btnText {
  box-shadow: 2px 2px 8px #999 !important;
}

.boxCeleste .loginForm span {
  color: $black;
}

.login-box {
  background-color: $bgBlue;
  color: $white;
  padding: 16px;
  border-radius: 16px 0;
  box-shadow: -2px -2px 20px $silverGray !important;

  .buttonGreen {
    background-color: $ended;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 15px;
    padding: 10px 15px;
    border: none;
    display: block;
    width: 80%;
    margin: 0 auto;
    text-decoration: none;
    cursor: pointer;
  }
}

.buttonGreen {
  background-color: #00d600;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 10px 15px;
  border: none;
  display: block;
  width: 80%;
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;
}

.fab {
  border-radius: 50%;
  border: 0;
  font-size: 28px;
  padding: 3px 6px 3px 6px;
  width: 40px;
  cursor: pointer;
  font-family: $Raleway;
  font-weight: 700;
  box-shadow: 2px 2px 8px $silverGray !important;
}

.cbWhiteSquare path {
  fill: $white;
}

.linkunderlined {
  color: $black;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-family: $Playfair;
  cursor: pointer;
}

.newsletterBox {
  background-color: $midGrey;

  img {
    width: 100px;
  }

  h5 {
    font-family: $Playfair, serif;
  }
}

a.linkblack {
  color: $black;
  text-decoration-color: $black;
}

.logoComune {
  height: 24px;
  vertical-align: bottom;
  margin: 0 5px;
}

.accountMenu {
  color: $black;
  text-align: center;
  position: relative;
  width: 31px;

  svg {
    margin: 0 auto;
  }
}

.accountMenu:after {
  position: absolute;
  content: 'ACCEDI';
  top: 24px;
  font-family: $Raleway;
  font-weight: 800;
  font-size: 7pt;
  color: $black;
  text-align: center;
  width: 35px;
  left: -2px;
}

.menuImageContainer {
  text-align: center;

  img {
    max-height: 40px;
  }
}

.home,
.caregiver-home {
  /*
  .carousel{
    min-height: 350px;

    >div:last-of-type{
      position: absolute;
      bottom:0;
    }
  }*/
  .favCaregivers.carousel > div:first-child {
    min-height: 440px; /* era 400 */
  }

  .carousel {
    .navButtons {
      svg {
        width: 2em !important;
        height: 2em !important;
      }
    }
  }

  .caregiver-box {
    margin-top: 15px;

    .bioContainer {
      height: 118px; /* era 78 */
      overflow: hidden;
    }

    .imgContainer {
      position: relative;

      .heart {
        position: absolute;
        background-color: transparent;
        border: 0;
        top: 8px;
        right: 18%;

        svg {
          width: 2em;
          height: 2em;
          color: $red;
        }
      }
    }

    h3 {
      font-size: 2rem;
      font-family: $Playfair;
    }

    p {
      font-family: $Playfair;
    }
  }

  text-align: center;

  .main-content {
    min-height: 1000px;
  }

  .titleNL {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.8rem;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    font-family: $Playfair;
    font-weight: normal;
    margin: 0;
  }

  .header {
    position: relative;
    z-index: -1;
    border-radius: 20px;
    overflow: hidden;
    top: 8px;

    img {
      width: 100%;
      position: relative;
      top: -20px;
    }
  }

  .logo {
    width: auto;
    margin-top: -25%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 10px 15% 0;
  }

  .afterLogo {
    font-size: 1.2em;
  }

  .findCaregiverBox {
    background-color: $lightGrey;
  }

  .topBox {
    background: $lightGrey;
    border-radius: 10px 10px 0 0;
    margin-top: 32px;
    padding: 20px 30px;

    h4 {
      font-family: $Playfair;
      font-size: 1.8rem;
      padding-bottom: 30px;
      padding-top: 10px;
    }

    h5 {
      font-family: $Playfair;
      font-weight: bold;
      padding-bottom: 5px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    img {
      max-width: 20%;
    }
  }

  .infoBox1 {
    margin-top: 10px;

    h5 {
      font-family: $Playfair;
      font-weight: bold;
    }

    img {
      max-width: 120px;
    }
  }

  .circularImg {
    border-radius: 50%;
  }
}

.MuiBackdrop-root {
  background-color: $bgAzure !important;
}

.yellow-modal .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.99) !important;
}

.color-EMERGENZA .MuiBackdrop-root {
  background-color: rgba(255, 203, 0, 0.7) !important;
}

.color-DOMICILIARE .MuiBackdrop-root {
  background-color: rgba(194, 224, 255, 0.7) !important;
}

.color-FAMILIARE .MuiBackdrop-root {
  background-color: rgba(179, 176, 176, 0.7) !important;
}

#pay-info-modal-modal-title {
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}

.yellow-modal .helpModal {
  border-radius: 20px 20px 20px 20px !important;
}

.helpModal {
  #modal-modal-title {
    font-size: 1.1rem;
    text-align: center;
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-family: $Raleway;
  }

  border-radius: 20px 20px 0 0;
  border: 0 !important;

  .circularImg {
    border-radius: 50%;
    max-width: 120px;
  }

  .iconClose {
    position: absolute;
    right: 0;
    top: 12px;
    text-align: center;
    background-color: transparent;
    border: none;
    z-index: 20;
    font-size: 7pt;
  }
}

.helpModal.caregiver-info {
  .info-box {
    .card-body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 400px;
    }
  }

  .iconClose {
    float: none;
    position: absolute;
    right: 16px;
    top: 10px;
    background-color: $white;
    font-family: $Raleway;
    font-size: 8pt;
  }

  .floating-form-icon {
    position: unset;
    float: left;
    width: 24px;

    > div {
      top: 0;
    }
  }
}

.caregiver-home .main-content {
  min-height: auto;
}

.loginModal {
  font-family: $Raleway;
  font-size: 13px;
  background-color: $bgBlue;
  border-radius: 0 0 0 30px;

  label {
    span {
      font-family: $Raleway;
      font-size: 13px;
      padding: 5px;
    }
  }

  button {
    font-family: $Raleway;
    font-size: 14px;
  }

  .circularImg {
    border-radius: 50%;
    max-width: 200px;
  }

  .iconClose {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    background-color: transparent;
    border: none;
    z-index: 20;
    font-family: $Raleway;
    font-size: 8pt;
    color: $white;
  }

  .loginForm {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      padding: 10px;
      border: 0;
    }
  }
}

.buttonWhite {
  background-color: $ended;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 10px 15px;
  border: none;
  display: block;
  width: 80%;
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;
}

label.check-label {
  font-family: $Raleway;
  font-weight: bold;
  /*text-transform: uppercase;*/
  font-size: 0.95rem;
  padding-right: 16px;
}

.book,
.caregiver-home,
.editorial {
  .main-content {
    /*min-height: 1000px;*/
  }

  label.check-label {
    position: relative;
    top: -2px;
  }

  .MuiListItemText-primary {
    font-family: $Raleway;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.7rem;
  }

  ul {
    .fab {
      border-radius: 50%;
      padding: 8px;
      border: 0;
      font-size: 0.95em;
      width: 32px;
    }
  }

  select {
    padding: 10px;
    border: 0;
  }

  input[type='text'] {
    padding: 10px;
    border: 0;
  }

  .boxCeleste {
    /*background: $celeste;*/
    background-color: $domiciliare-light;
    border-radius: 20px; /* was 10px */
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 16px;

    .app-text:first-child {
      margin-top: 0;
    }
  }

  .boxCeleste.EMERGENZA {
    background: $emergenza-light !important;
  }

  .boxCeleste.FAMILIARE {
    background: $familiare-light !important;
  }

  .boxCeleste.large-side-padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  .boxCeleste.no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .boxBianco {
    background: $white;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px !important;
    /*border-bottom: black 1px solid;*/
  }

  .boxBianco.large-side-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .boxBianco.no-side-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .boxError {
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 30px;
    background-color: $boxError;
  }

  .boxSuccess {
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 30px;
    background-color: $boxSuccess;
  }
}

/* Position and sizing of burger button */
.bm-overlay {
  background-color: $bgAzure !important;
  opacity: 0.5 !important;
}

.bm-burger-button {
  position: relative;
  width: 28px;
  height: 20px;
  display: inline-block;
}

.bm-burger-button:after {
  position: relative !important;
  content: 'MENU';
  top: 12px;
  font-family: $Raleway;
  font-weight: 800;
  font-size: 7pt;
  color: $black;
}

.menuServices {
  .menu-item {
    text-transform: uppercase;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $bluishGray;
  border-radius: 5px;
}

.bm-burger-bars:nth-child(2) {
  top: 33% !important;
}

.bm-burger-bars:nth-child(3) {
  top: 66% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $bluishGray;
}

#accountMenu {
  .MuiDrawer-paper {
    //width: 100% !important;
    max-width: 500px;
    border-radius: 30px 0 0 0;
    background-color: transparent;
    color: $white;
    width: 100%;
    box-shadow: unset;

    .accountMenuContainer {
      position: relative;
      border-radius: 0 0 0 30px;

      a {
        color: $white;
        text-decoration: none;
        line-height: 1em;
      }

      li {
        justify-content: end;
        min-height: auto;
      }

      .privacyItems {
        a {
          font-family: $Raleway;
          text-decoration: underline;
          font-weight: 300;
        }
      }

      .menuItems {
        li {
          font-family: $Playfair;
          font-size: 18pt;
          font-weight: 300;
        }

        a {
          font-weight: 500;
          font-family: $Playfair;
          font-size: 18pt;
        }
      }
    }

    .iconClose {
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      background-color: transparent;
      border: none;
      z-index: 20;
      font-family: $Raleway;
      font-size: 8pt;
      color: $white;
    }
  }
}

.burgerMenu {
  height: auto !important;
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 32px;
    width: 32px;
    right: 20px !important;
    top: 20px !important;
  }

  .bm-cross-button:after {
    position: absolute;
    content: 'CHIUDI';
    left: -6px;
    top: 24px;
    font-size: 8pt;
    font-family: $Raleway;
    font-weight: 400;
    color: $white;
    width: 30px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: transparent;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  &.bm-menu-wrap {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 500px;
  }

  /* General sidebar styles */
  .bm-menu {
    background-color: $darkGrey;
    padding: 1.5em 0.5em 0;
    font-size: 1.15em;
    border-radius: 0 40px 40px 0;
    box-shadow: 2px 2px 8px $black !important;
  }

  @media screen and (max-height: 480px) {
    .bm-menu {
      padding: 0 !important;
    }
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: $goldishGray;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    a {
      display: block;
      font-family: $Raleway;
    }

    &.menuTop {
      text-align: left;

      a {
        text-decoration: none;
        font-weight: bold;
        padding-bottom: 7px;
      }

      .menuPages {
        a {
          color: $white;
          font-weight: normal;
          font-family: $Playfair;
          font-size: 1.4rem;
        }

        margin-top: 30px;
      }
    }

    &.menuBottom {
      text-align: left;
      font-size: 0.9rem;

      a {
        color: $white;
        padding-bottom: 5px;
        text-decoration: none;
      }

      @media screen and (min-width: 300px) {
        padding-bottom: 30px;
      }
      margin-top: 30px;

      .menuBottomFooter {
        position: relative;
      }

      .menuPrivacy {
        margin-top: 1rem;

        a {
          color: $lightGrey;
          text-decoration: underline;
        }
      }

      .menuContacts {
        float: right;
        color: $white;

        .text {
          font-size: 0.7rem;
          white-space: nowrap;
        }

        .number {
          font-size: 1.1rem;
          line-height: 1rem;
          white-space: nowrap;
          font-weight: bold;
          font-family: $Oswald;
        }

        .icoContainer {
          width: 32px;
          display: inline-block;
          position: relative;

          img {
            bottom: 0;
            width: 27px;
            position: absolute;
          }
        }

        .whatsapp {
          border-bottom: none;
          margin-top: 7px;
          color: #14ff00;

          .icoContainer {
            img {
              bottom: -7px;
              height: 32px;
            }
          }
        }
      }
    }
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: $goldishGray;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $bluishGray;
}

/* Styling of overlay */
.bm-overlay {
  left: 0;
  top: 0;
}

#footer {
  font-family: $Raleway;
  font-size: 0.9em;
  background-color: $familiare;

  .footer-header {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: $black;
    font-weight: bold;
  }

  .text-semibold {
    text-align: center;
    padding: 0 20px;
    font-weight: 600;
  }

  border-radius: 25px 25px 0 0;
  padding: 16px;
  margin-top: 20px;

  .links {
    text-align: left;
  }

  .info {
    font-weight: 600;
    text-align: center;

    .text {
      font-family: $Playfair;
      line-height: 2px;
      font-weight: 500;
    }

    p {
      font-size: small;
      font-weight: 400;
    }
  }

  a {
    color: $black;
    text-decoration: none;
  }
}

.user-label {
  color: $black;
  position: relative;
  top: -1em;
  font-weight: 700;
  font-size: 0.8em;
}

.loading {
  width: 100%;
  text-align: center;
}

.list-mansioni {
  /*max-height:400px;
  overflow: scroll;*/
  margin-top: 4px !important;
  max-width: unset !important;
}

.pending-changes input,
.pending-changes select {
  border: 2px solid $red !important;
  position: relative;
  top: -2px;
}

.loading-container {
  text-align: center;
}

.large-icon {
  padding-top: 0.5em;
  transform: scale(1.2);
}

.large-icon.no-padding {
  padding-top: 0;
}

.larger-icon {
  //padding-top: 0.5em;
  transform: scale(1.6);
  color: $emergenza;
}

.larger-icon.no-padding {
  padding-top: 0;
}

.small-icon {
  transform: scale(0.6);
}

.small-icon-legend {
  position: relative;
  top: -8px;
}

.red-icon {
  fill: #a63030 !important;
}

.blue-icon {
  fill: #1976d2 !important;
}

.green-icon {
  fill: $ended !important;
}

.black-icon {
  fill: $black !important;
}

.MuiTextField-root input {
  background-color: $white;
  margin: 2px;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
  font-weight: bold;
  font-family: $Raleway;
}

.MuiTextField-root textarea {
  background-color: $white;
  margin: 2px;
  font-weight: bold;
  font-family: $Raleway;
}

.MuiInputBase-multiline {
  background-color: $white;
  border-color: $black;
}

.MuiTextField-root label {
  top: 0.7em;
  left: -0.1em;
  font-weight: normal;
  font-family: $Raleway;
  color: $black;
  background-color: transparent;
}

.MuiTextField-root legend span,
.MuiSelect-root legend span {
  display: none;
}

p.MuiFormHelperText-root.Mui-error {
  color: $white;
  background-color: #d32f2f;
  padding: 2px 7px;
  margin-left: 2px;
  margin-right: 2px;
}

.MuiSelect-root div[role='button'] {
  background-color: $white;
  margin: 2px;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
  font-weight: bold;
  font-family: $Raleway;
}

label.select-label {
  position: absolute;
  z-index: 1000;
  font-weight: normal;
  font-family: $Raleway;
  color: $black;
  background-color: transparent;
  font-size: 0.8em;
  padding-left: 14px;
  padding-top: 4px;
}

label.select-label.disabled {
  color: rgba(0, 0, 0, 0.38);
}

.floating-form-icon {
  position: absolute;
}

.floating-form-icon > div {
  position: relative;
  left: -5px;
  top: 14px;
}

.eq-form-group {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 24px;
}

.eq-form-group .label-outer label {
  font-weight: normal;
  font-family: $Raleway;
  color: $black;
  font-size: 0.8em;
  font-weight: normal;
  position: relative;
  top: -8px;
  left: -12px;
  background-color: $white;
}

.group-contents {
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  left: -12px;
  top: 6px;
}

.boxCeleste .eq-form-group .label-outer label {
  /*background-color: $celeste;*/
  background-color: #e3efff;
}

.boxCeleste.EMERGENZA .eq-form-group .label-outer label {
  /*background-color: $emergenza;*/
  background-color: #fbf1b1;
}

.boxCeleste.FAMILIARE .eq-form-group .label-outer label {
  background-color: $familiare;
}

.eq-form-group .label-outer {
  position: absolute;
}

.error-alert {
  position: fixed;
  top: 10px;
  z-index: 100000;
  left: 10px;
  border: 2px solid #ef5350;
  right: 10px;
}

.error-confirm {
  position: fixed;
  top: 10px;
  z-index: 100000;
  left: 10px;
  border: 2px solid #1ecb40;
  right: 10px;
}

#ll_alert_msg,
#ll_alert_msg_confirm {
  width: 100%;
  text-align: center;
}

.bookstep-start-label {
  font-size: 1.5em;
}

.in-cart.total {
  background-color: #e3e3e3 !important;
  padding: 18px 16px 10px;
  margin-bottom: 30px;
  font-family: $Raleway !important;

  .price {
    font-weight: bold;
    font-size: 110%;
  }
}

.caregiver-card.in-cart.coupon {
  .price {
    font-weight: bold;
    font-size: 110%;
  }
}

.caregiver-card {
  box-shadow: 0 0 10px $lightGrey !important;
  /*width:calc(100% - 20px);*/
  margin: auto;
  padding-top: 6px;
  border-radius: 8px !important;
}

.caregiver-card.in-cart {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.col1 {
  display: inline-block;
  padding-right: 8px;
  width: 80px;
  text-align: right;
  vertical-align: top;
}

.col1-icon {
  display: inline-block;
  padding-right: 4px;
  width: 40px;
  text-align: right;
  vertical-align: top;
}

.cart .col1 {
  width: 30px;
  position: relative;
  left: -10px;
  padding-left: 10px;
}

.col2 {
  display: inline-block;
  text-align: left;
}

.col2-icon {
  display: inline-block;
  text-align: left;
}

.col2l {
  text-align: left;
  float: left;
  width: calc(100% - 120px);
}

.col2r {
  text-align: left;
  float: right;
  width: 80px;
  padding-left: 8px;
  padding-right: 20px;
  vertical-align: top;
}

.explanation {
  .col2 {
    width: calc(100% - 90px);
  }
}

.cart {
  .explanation {
    .col2 {
      width: calc(100% - 50px);
    }
  }
}

.solution-list-container {
  .col1 {
    svg {
      position: relative;
      top: -10px;
    }
  }

  .col2r {
    svg {
      position: relative;
      top: -10px;
    }

    .price {
      font-weight: bold;
      font-size: 110%;
      position: relative;
      top: -10px;
      text-align: right;
    }

    .price:not(.total) {
      padding-right: 6px;
      text-align: right;
      padding-top: 3px;
    }
  }
}

.alternatives {
  .solution-list-container {
    .col2r {
      .add-icon {
        width: 100%;
        text-align: right;
        padding-right: 6px;
        line-height: 36px;
      }

      svg {
        position: relative;
        top: 0;
      }
    }
  }
}

.caregiver-card,
.caregiver-info {
  .fav-outer {
    /*position: absolute;
    right:0;*/
    float: right;

    .fav-inner {
      position: relative;
      right: 10px;
      top: 10px;
      color: $red;
    }
  }

  .img-outer {
    width: 80px;
    min-width: 80px;
    max-width: 80px;

    img {
      border-radius: 4px;
    }
  }

  .card-body {
    width: calc(100% - 12px);
    padding-left: 6px;

    .solution {
      border: 1px solid transparent;
      border-radius: 6px;
      margin-bottom: 10px;
      margin-top: 6px;
    }

    .solution.solution-confirmed {
      border-color: green;
    }

    .solution.solution-in-cart {
      border-color: transparent;
    }

    .solution.solution-available {
      border-color: $silverGray;
    }

    .solution.solution-not-available {
      border-color: $silverGray;
    }

    .main-info {
      font-weight: 400;
      padding-top: 3px;
    }

    .message > div {
      text-align: center;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 80%;
      margin-bottom: 8px;
      border-bottom: 1px solid $silverGray;
    }

    .solution-confirmed .message > div {
      background-color: lightgreen;
      border-bottom: 1px solid green;
    }

    .solution-available .message > div {
      background-color: $white;
      border-bottom: 1px solid $white;
    }

    .solution-not-available .message > div {
      background-color: $white;
      border-bottom: 1px solid $silverGray;
    }

    .solution.solution-blocked {
      border-color: $silverGray;

      .price {
        margin-top: 40px;
      }
    }

    .solution-blocked .message > div {
      background-color: $white;
      border-bottom: 1px solid $silverGray;
    }

    .solution-blocked .explanation {
      opacity: 0.4;
    }

    .solution-not-available .explanation {
      opacity: 0.4;
    }

    .solution-list-container {
      font-weight: 600;
      width: 100%;
    }

    .solution-list {
      width: 100%;
      margin: auto;
    }

    .name {
      font-weight: bold;
      margin-bottom: 0;
    }

    .name,
    .role,
    .price {
      margin-left: 10px;
      font-family: $Raleway;
      color: $black;
      font-weight: 600;
    }

    .company {
      margin-left: 10px;
      font-family: $Raleway;
      color: $black;
      font-weight: 600;
      font-size: 75%;
      line-height: 1;
      margin-top: 4px;
    }

    .price {
      white-space: nowrap;
      text-align: center;
      margin-left: 0;
    }

    .coverage {
      margin-top: 10px;
      color: #1ecb40;
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-family: $Raleway;
      -webkit-font-smoothing: antialiased;
    }

    .experience {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-weight: 600;

      span {
        font-family: $Raleway;
        -webkit-font-smoothing: antialiased;
        text-decoration: underline;
        position: relative;
        top: -0.5em;
      }
    }

    .i-icon {
      position: relative;
      top: -8px;
    }
  }
}

h3.patient-name {
  margin-top: 0;
  margin-bottom: 0;
}

h3.patient-name span {
  position: relative;
  top: -4px;
}

.coupon-ok,
.otp-ok {
  position: absolute;
  right: 8px;
  z-index: 100;
  top: 11px;
  color: green;
}

.coupon-checking,
.otp-checking {
  position: absolute;
  right: 8px;
  z-index: 100;
  top: 11px;
  color: $turqisa;
}

.coupon-wrong,
.otp-wrong {
  position: absolute;
  right: 8px;
  z-index: 100;
  top: 11px;
  color: $red;
}

.MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border-color: $darkGrey;
  border-width: 2px;
}

.eq-select {
  width: 100%;
}

.eq-select .MuiOutlinedInput-notchedOutline {
  border-color: $darkGrey;
  border-width: 2px;
}

.Mui-disabled .MuiOutlinedInput-notchedOutline {
  color: $black !important;
  border-color: transparent !important;
}

.MuiTextField-root .Mui-disabled,
.MuiSelect-select.Mui-disabled {
  color: $black !important;
  -webkit-text-fill-color: $black !important;
  opacity: 1 !important;
  border-width: 2px;
  border-color: transparent;
}

.select-label.disabled {
  color: $black !important;
}

.boxCeleste .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: $black !important;
  border-width: 2px;
  border-color: transparent;
}

.eq-select .Mui-disabled .MuiOutlinedInput-notchedOutline {
  color: $black !important;
}

.error-icon {
  color: $red;
  transform: scale(4);
}

.success-icon {
  color: green;
  transform: scale(4);
}

.app-text {
  font-size: 14pt;
}

.app-text.small {
  font-size: 1rem;
}

.eq-checkbox .not-checked path {
  fill: $white;
}

.eq-checkbox svg {
  font-size: 32px !important;
  filter: drop-shadow(0 0 4px #bbb);
}

.eq-checkbox svg {
  font-size: 32px !important;
  filter: drop-shadow(0 0 4px #bbb);
}

.eq-stepper {
  svg {
    font-size: 50px;
    position: relative;
    top: -9px;
    color: #bdbdbd;
  }

  svg.eq-step-active,
  svg.eq-step-completed {
    color: $btnbluebg;
  }

  span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-width: 10px;
  }

  .Mui-active span.MuiStepConnector-line.MuiStepConnector-lineHorizontal,
  .Mui-completed span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-color: $btnbluebg;
  }

  .MuiStepLabel-label {
    font-size: 9px;
    margin-top: -10px !important;
    font-weight: 800 !important;
    font-family: $Roboto;
  }
}

.book-navigation {
  width: 100%;
  background-color: $white;
  padding: 10px 0 10px 0;
  margin: 0;
  z-index: 1001;
}

.notification-area {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: $white;
  left: 0;
  padding: 0 10px 0 10px;
  margin: 0;
  border-top: 2px solid $silverGray;
  z-index: 2;
}

.book-navigation.enlarge {
  margin-left: -10px;
  margin-right: -10px;
}

.book-navigation.shrink {
  padding-left: 3px;
  padding-right: 3px;

  > div:nth-child(2) {
    padding-right: 8px;
  }
}

.notification-area.has-notifications {
  z-index: 10000;
  background-color: lightcoral;
  border-top: 2px solid $red;
}

.notification-area > div {
  width: 100%;
  z-index: 100;
}

.small-note {
  font-size: 12px;
}

.eq-weekend-picker {
  .Mui-selected {
    color: $white !important;
    background-color: #1976d2 !important;
  }

  .MuiTypography-caption:last-child {
    visibility: hidden;
    max-width: 10px;
  }

  .MuiTypography-caption:nth-child(6) {
    visibility: hidden;
    width: 70px;
    text-align: center;
    padding-right: 4px;
  }

  .MuiTypography-caption {
    font-weight: bold;
  }

  .MuiTypography-caption:nth-child(6):after {
    content: 'SA DO';
    visibility: visible;
    display: block;
  }

  .MuiPickersDay-dayWithMargin:last-child {
    visibility: hidden;
    max-width: 10px;
  }

  .MuiPickersDay-dayWithMargin {
    font-weight: bold;
  }

  .eq-weekend-btn.future {
    background-color: $lightGrey !important;
    border-radius: 20px;

    span {
      width: 30px;
      font-weight: bold;
      color: $black;
    }
  }

  .eq-weekend-btn.past {
    background-color: $white !important;
    border-radius: 20px;

    span {
      width: 30px;
      font-weight: normal;
      font-size: 12px;
      color: #aaa;
    }
  }

  .eq-weekend-btn.eq-weekend-selected {
    background-color: $celeste !important;
  }

  .MuiCalendarPicker-viewTransitionContainer > div {
    padding-left: 20px;
  }
}

.eq-select span.second-label {
  font-weight: normal;
  font-size: 0.8em;
  color: $black;
}

.codice-prenotazione {
  div.label {
    font-size: 60%;
  }

  div.code {
    font-weight: bold;
    font-size: 16pt;
  }
}

.voucher-icons {
  width: 110px;
  float: right;
  margin-right: 20px;
  position: relative;
  top: -10px;

  > div {
    width: 50%;
    float: right;

    div {
      font-size: 60%;
      font-weight: bold;
    }
  }
}

.da-sapere {
  margin-bottom: -25px;

  .exclamation-logo span {
    margin: auto;
    background-color: $yellow;
    display: inline;
    padding: 2px 15px 2px 15px;
    border-radius: 30px;
    font-family: $Playfair !important;
    font-weight: bold;
    font-size: 18pt;
    position: relative;
    top: -25px;
    left: 60px;
    border: 6px solid $white;
  }

  .label {
    width: auto;
    margin: auto;
    background-color: $yellow;
    display: inline;
    padding: 10px 20px 10px 10px;
    border-radius: 20px 0 0 20px;
    font-family: $Playfair !important;
    font-weight: bold;
  }
}

.text-danger {
  color: $red;
}

.bandaGialla {
  background: #faeda2;

  > div > p {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.home {
  .bandaGialla {
    margin-right: -16px;
    margin-left: -16px;
  }
}

.openInvComma {
  width: 130px;
  fill: $commaOpen;
}

.closeInvComma {
  svg {
    width: 50px;
    fill: $commaClosed;
  }

  text-align: right;
}

.yellowBg {
  background-color: $sand;

  .closeInvComma {
    svg {
      width: 50px;
      fill: $black;
    }
  }
}

.caregiver-home {
  .lost-password {
    text-decoration: underline;
    padding-top: 20px;
    display: block;
  }

  .caregiver-new {
    position: relative;
    margin-top: 60px;
    margin-bottom: 0;
    padding-bottom: 0;
    top: -75px;
    z-index: 20;
    border-radius: 20px !important;
    background-color: #e3e3e3;
    height: 200px;
  }

  @media screen and (max-height: 600px) {
    .caregiver-new {
      height: unset;
    }
  }

  .accountMenu.logged:after {
    left: -2px;
  }
}

.rolling-calendar-arrow-left > div {
  height: 76px;
  box-sizing: border-box;
  padding-top: 24px;
  /*background-color: $celeste;*/
}

.rolling-calendar-arrow-right > div {
  height: 76px;
  box-sizing: border-box;
  padding-top: 24px;
  /*background-color: $celeste;*/
  position: relative;
  left: -1px;
}

.rolling-calendar-outer {
  width: 100%;
  overflow: auto;
  /*border: 4px solid $celeste;*/
  border: 4px solid $white;
  /*background:linear-gradient(90deg, $silverGray 0, $white 33%, $white 66%, $silverGray 100%);*/
}

.rolling-calendar th {
  color: $cyan;
  font-weight: normal;
  color: $black;
}

.rolling-calendar td {
  min-width: 20px;
  cursor: pointer;
}

.rolling-calendar td.DO {
  color: #aaa;
}

.calendar-icon {
  transform: scale(1.3);
}

.rolling-calendar td > div {
  padding: 8px 10px 8px 10px;
  border-radius: 20px;
  width: 16px;
  font-weight: bold;
  margin: 4px;
}

.rolling-calendar td.selected > div {
  background-color: $cyan;
  color: $white;
}

.rolling-calendar td.with-events > div {
  background-color: $celeste;
}

.hour-row {
  height: 20px;
  max-height: 20px;
  min-height: 20px;
}

.hour-row > div:first-child {
  width: 16%;
  position: relative;
  top: -11px;
  background-color: $white;
}

.scrolling-hours {
  /*height: 400px;*/
  overflow-y: hidden;
  overflow-x: hidden;
}

.scrolling-hours .loading-outer {
  margin-top: 150px;
}

.hour-row > div:nth-child(2) {
  width: 83%;
}

.hour-row:nth-child(even) {
  border-top: 1px solid #aaa;
  box-sizing: border-box;
}

.hour-row:nth-child(odd) > div:first-child {
  visibility: hidden;
}

.selected-date {
  background-color: #ececec;
  padding: 5px;
  font-weight: bold;
  text-align: center;
}

.rolling-month {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 40px;
}

.rolling-month > div:nth-child(1),
.rolling-month > div:nth-child(3) {
  width: 18%;
  transform: scale(1.9);
  display: inline-block;
  color: #aaa;
  box-sizing: border-box;
}

.rolling-month > div:nth-child(1) {
  padding-left: 20px;
}

.rolling-month > div:nth-child(3) {
  padding-right: 20px;
}

.rolling-month > div:nth-child(2) {
  display: inline-block;
  font-size: 1.6rem;
  margin-bottom: 30px;
  position: relative;
  top: -8px;
  color: $cyan;
}

.calendar-events {
  height: 18px;
  max-height: 18px;
  cursor: pointer;
}

.calendar-events .calendar-event {
  position: relative;
  background-color: transparent;
  z-index: 30;
  left: 16%;
  width: 83%;
  text-align: left;
  font-size: 10pt;
  /*padding-left: 1%;  
  border-left: 8px solid $gray;*/
  color: $black;
  font-weight: 600;
  top: 338px;
  display: flex;
  /* justify-content: center; */
  /*align-items: center;*/
  line-height: 40px;
}

.calendar-events .calendar-event:after {
  content: url('https://app.equecure.com/img/ico_i.png');
  /*content: url('/img/ico_i.png');*/
  display: block;
  position: absolute;
  right: 0;
  padding-top: 4px;
}

.calendar-events .calendar-event.event-1000:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid $basaltGray;
  border-right: 6px solid $basaltGray;
  background-color: $basaltGray;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.calendar-events .calendar-event.event-2:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid $green;
  border-right: 6px solid $green;
  background-color: $green;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.serviceFooter.event-5 {
  background-color: $cyan;
  color: $black;
}

.calendar-events .calendar-event.event-5:before,
.calendar-events .calendar-event.event-9:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid $sand;
  border-right: 6px solid $sand;
  background-color: $sand;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.serviceFooter.event-5 {
  background-color: $sand;
  color: $black;
}

.calendar-events .calendar-event.event-6:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid rgb(230, 164, 164);
  border-right: 6px solid rgb(230, 164, 164);
  background-color: rgb(230, 164, 164);
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.serviceFooter.event-6 {
  background-color: rgb(230, 164, 164);
  color: $black;
}

.calendar-events .calendar-event.event-late:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid rgb(230, 164, 164);
  border-right: 6px solid rgb(230, 164, 164);
  background-color: rgb(230, 164, 164);
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.calendar-events .calendar-event.event-4:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid $red;
  border-right: 6px solid $red;
  background-color: $red;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.calendar-events .calendar-event.event-3:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid #9492e8;
  border-right: 6px solid #9492e8;
  background-color: #9492e8;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.calendar-events .calendar-event.event-7:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 6px solid #1ecb40;
  border-right: 6px solid #1ecb40;
  background-color: #1ecb40;
  border-radius: 6px;
  margin-right: 6px;
  opacity: 0.7;
  margin-top: 1%;
}

.serviceFooter.event-8 {
  background-color: #1ecb40;
  color: $black;
}

.calendar-events .calendar-event.event-8:before {
  content: '';
  display: block;
  height: 94%;
  border-left: 4px solid #206934;
  border-right: 4px solid #206934;
  background-color: #1ecb40;
  border-radius: 4px;
  margin-right: 4px;
  opacity: 0.7;
}

.serviceFooter.event-7 {
  background-color: #1ecb40;
  color: $black;
}

.large-number {
  font-size: 32pt;
  color: $black;
}

.highlightPrenotazione {
  border: 2px solid $cyan;
  border-radius: 10px;
  margin-top: 8px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 8px 8px 20px;
  max-height: 380px;
  height: 380px;
  overflow-y: auto;
}

.highlightPrenotazione * {
  font-size: 95%;
}

.highlightPrenotazione .lbl {
  float: left;
}

.highlightPrenotazione .val {
  float: right;
  text-align: right;
}

.highlightPrenotazione .mt-1,
.toBeConfirmed .mt-1 {
  margin-top: 10px;
}

.highlightPrenotazione .separator,
.toBeConfirmed .separator {
  position: relative;
}

.highlightPrenotazione .separator > div,
.toBeConfirmed .separator > div {
  border-bottom: 1px dashed $gray;
  width: 100%;
  position: absolute;
  height: 19px;
}

.event-modal-state {
  /*margin-top: 20px;*/
  /*margin-bottom: 20px;*/
  border-radius: 0 0 20px 20px !important;
  /*box-shadow: 2px 2px 4px $basaltGray !important;*/

  .serviceHeader {
    line-height: 26px;
    width: 100%;
    height: 24px;
    font-size: 0.8rem;
    border-radius: 0 0 4px 4px;
    text-align: center;
  }

  .serviceFooter {
    width: 100%;
    height: 24px;
    font-size: 0.8rem;
    border-radius: 0 0 4px 4px;
    text-align: center;

    .serviceFooterContainer {
      padding: 4px 0 4px 20px;

      img {
        height: 12px;
        vertical-align: middle;
        margin-right: 10px;
      }

      svg {
        height: 14px;
        vertical-align: middle;
      }
    }
  }

  .serviceContents {
    padding: 0 20px 0 20px;
  }
}

.event-modal-state.can-accept,
.event-modal-state.can-accept .serviceFooter,
.event-modal-state.can-accept .serviceHeader,
.event-modal-state.in-late {
  background-color: $to-accept;
}

.event-modal-state.refused {
  background-color: $refused;
  color: $white;
}

.event-modal-state.ended {
  background-color: $ended;
  color: $white;
}

.event-modal-state.started {
  background-color: $started;
}

.modal-title {
  position: relative;
  top: -12px;
  z-index: 10;
  width: 80%;
  padding-left: 10%;
}

.toBeConfirmed * {
  font-size: 95%;
}

.toBeConfirmed {
  margin-top: 8px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 8px 8px 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.large-time-icon {
  padding-top: 0.5em;
  transform: scale(1.8);
  width: 40px;
}

.time-info {
  display: flex;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 85%;
  font-weight: bold;
}

.time-info > div:nth-child(2) {
  padding-left: 20px;
}

.eventModal {
  margin-bottom: 100px;

  > div:first-child {
    border: 1px solid $silverGray;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 10px;
    border-radius: 5px;
    padding-bottom: 20px;
    box-shadow: 2px 2px 4px $basaltGray !important;
  }

  .boxPass {
    background: $white;
    border-radius: 20px; /* was 10px */
    padding: 10px 10px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: -10px;
    border: 2px solid $black;

    label {
      display: block;
      font-size: 0.5em;
      line-height: 0.5em;
    }
  }

  .iconClose-outer {
    position: absolute;
    right: 10px;
  }

  .iconClose-outer .iconClose {
    position: relative;
    top: -10px;
    font-family: $Raleway;
    font-size: 8pt;
  }

  .inline-block {
    display: inline-block;
  }

  .bookDetailContainer {
    line-height: 1.5rem;
    margin-top: 30px;

    .serviceAssistant {
      padding-top: 10px;

      .bookDetailIconSpacer {
        vertical-align: bottom;
      }

      & > div {
        display: inline-block;
      }

      img {
        position: absolute;
        bottom: 0;
      }
    }

    .bookDetailIconSpacer {
      width: 35px;
      position: relative;
      vertical-align: top;

      img {
        padding-top: 5px;
      }
    }

    .bookDetailContent {
      width: calc(100% - 45px);
    }

    .dateText {
      text-transform: lowercase;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
}

.late-btn {
  background-color: $white;
  color: $black;
  display: block;
}

.start-btn {
  display: block;
}

.caregiver-home .boxBianco.calendar {
  padding: 16px 16px !important;
  padding-top: 0 !important;
}

.in-late-message {
  color: $red;
  font-weight: bold;
  font-size: 12pt;
  margin-top: 4px;
}

.caregiver-home .burgerMenu .bm-item.menuTop a {
  color: $white;
}

.caregiver-home .burgerMenu .bm-item.menuTop a:visited {
  color: $white;
}

.caregiver-home .burgerMenu .bm-item.menuTop a:hover {
  color: $white;
}

.caregiver-home .account-burger {
  position: absolute;
  top: 10px;
  color: $white;
  display: flex;
  flex-direction: column;
}

.caregiver-home .account-burger > div:nth-child(1) {
  text-align: center;
}

.caregiver-home .account-burger > div:nth-child(2) {
  font-size: 70%;
}

.caregiver-home .menuServices {
  margin-top: 40px;
}

.caregiver-home .burgerMenu .bm-item.menuBottom .menuContacts {
  margin-top: 0;
}

.caregiver-home .burgerMenu .bm-item.menuBottom .menuContacts-title {
  margin-top: 40px;
  color: $white;
  margin-left: 16px;
}

.caregiver-home input[type='text']:read-only {
  /*background-color: $celeste;*/
}

.boxGrigio {
  background: #e3e3e3;
  border-radius: 20px;
  margin-top: 10px;
  /*margin-bottom:10px;*/
  padding: 20px 10px;
}

.logged-user-alert {
  position: fixed;
  bottom: 0;

  text-align: center;
  background: $red;
  color: $white;
  height: 72px;
  vertical-align: middle;
  padding-top: 4px;
  border-radius: 8px 8px 0 0;
  z-index: 100;
  left: 0;
  right: 0;

  > div:first-child {
    position: relative;
    top: -12px;
    background-color: $red;
    margin: auto;
    border-radius: 8px 8px 0 0;
    width: 25px;
    padding-top: 0;
  }

  > div:nth-child(2) {
    position: relative;
    top: -24px;
  }

  .title {
    font-size: 85%;
  }

  .boxes {
    display: flex;
    justify-content: center;
    gap: 1px;
    align-items: stretch;

    > div {
      border: 1px solid $white;
      padding: 4px 10px 4px 8px;
      border-radius: 0 0 4px 4px;
    }

    .info {
      height: 28px;
      max-height: 28px;
      overflow: hidden;
      vertical-align: middle;
    }

    .legend {
      text-align: center;
      background-color: $white;
      color: $red;
      font-size: 85%;
      margin-right: -8px;
      margin-left: -8px;
      margin-bottom: -4px;
      padding-right: 4px;
      padding-left: 4px;
    }
  }
}

.caregiver-home .logged-user-alert {
  margin-left: 0;
}

.logged-user-alert.to-be-confirmed {
  background: $sand;
  color: $black;

  > div:first-child {
    background-color: $sand;
    color: $black;
  }

  .boxes {
    > div {
      border: 1px solid $black;
    }

    .legend {
      color: $sand;
      background-color: $black;
    }
  }
}

.logged-user-alert.in-late .state {
  background-color: $sand;

  .info {
    color: $black;
  }

  .legend {
    color: $black;
  }
}

.bookCard {
  border-radius: 5px;
  /*box-shadow: 2px 2px 4px $basaltGray !important;*/
  position: relative;

  .serviceBody {
    padding: 15px;
  }

  .serviceTitle {
    padding-left: 35px;
    position: relative;

    .infoIcon {
      position: absolute;
      right: 10px;
    }
  }

  .serviceTime {
    position: relative;
    padding-top: 7px;

    & > div {
      display: inline-block;
    }

    .calIcon {
      width: 35px;
      position: relative;
      vertical-align: top;

      img {
        padding-top: 5px;
      }
    }

    .dateText {
      text-transform: lowercase;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .serviceAssistant {
    padding-top: 10px;

    & > div {
      display: inline-block;
    }

    .assistantIcon {
      width: 35px;
      position: relative;

      img {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .serviceNumber {
    padding: 10px 0;
    text-align: right;

    label {
      font-size: 0.7rem;
    }
  }

  .serviceFooter {
    position: absolute;

    width: 100%;
    font-size: 0.8rem;
    border-radius: 0 0 4px 4px;

    .serviceFooterContainer {
      padding: 4px 0 4px 20px;

      img {
        height: 12px;
        vertical-align: middle;
        margin-right: 10px;
      }

      svg {
        height: 14px;
        vertical-align: middle;
      }
    }
  }
}

#counter-table {
  margin-top: 20px;
  border-spacing: 10px;
  border-collapse: separate;

  td {
    width: 50%;
  }

  .container {
    border-radius: 10px;
    box-shadow: 2px 2px 4px $silverGray !important;
    border: 1px solid $white;
  }

  .large-number {
    padding-top: 6px;
    margin-bottom: 6px;
  }
}

.bottom-alerts-container {
  position: absolute;
  left: 0;
}

#ll_alert_div .error-alert {
  background-color: $white;
  color: $black;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: $Roboto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: rgb(253, 237, 237);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  color: rgb(95, 33, 32);
}

#ll_alert_div_confirm .error-confirm {
  background-color: $white;
  color: $black;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: $Roboto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #c9f2c9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  color: #1ecb40;
}

.yellow_confirm_dialog > div:first-child > div:first-child > div:first-child {
  background-color: $sand !important;
}

.yellow_confirm_dialog .MuiDialogContent-root p {
  font-weight: bold !important;
}

.yellow_confirm_dialog .MuiDialogActions-root {
  justify-content: center !important;
  gap: 20px !important;
}

.yellow_confirm_dialog .MuiDialogActions-root button:first-child {
  border: 2px solid $black;
  background-color: $red;
  color: $white;
  border-radius: 30px;
  width: 42px;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;

  min-width: auto;
}

.yellow_confirm_dialog .MuiDialogActions-root button:nth-child(2) {
  border: 2px solid $black;
  background-color: $ended;
  color: $white;
  border-radius: 30px;
  width: 42px;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;

  min-width: auto;
}

.caregiver-alert-badge {
  background-image: url('https://app.equecure.com/img/ico_tri.png');
  height: 32px;
  width: 36px;
  color: $black;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  padding-left: 0px;
}

.bookModal {
  .iconClose {
    text-align: center;
    background-color: transparent;
    border: none;
    z-index: 20;
    font-family: $Raleway;
    font-size: 8pt;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
  }

  .inline-block {
    display: inline-block;
  }

  /*font-size: .9rem;*/
  p {
    font-size: 0.8rem;
  }

  .bookDetailContainer {
    line-height: 1.3rem;

    .serviceAssistant {
      padding-top: 10px;

      .bookDetailIconSpacer {
        vertical-align: bottom;
      }

      & > div {
        display: inline-block;
      }

      img {
        position: absolute;
        bottom: 0;
      }
    }

    label {
      font-size: 0.8rem;
    }

    .bookDetailIconSpacer {
      width: 35px;
      position: relative;
      vertical-align: top;

      img {
        padding-top: 5px;
      }
    }

    .bookDetailContent {
      width: calc(100% - 45px);
    }

    .dateText {
      text-transform: lowercase;
      font-weight: bold;
      font-size: 1.1rem;
    }

    .bookDetailInfo {
      line-height: 1rem;
    }
  }

  .serviceFooter {
    position: absolute;
    bottom: -10px;
    width: 100%;
    font-size: 0.8rem;
    border-radius: 8px 8px 0 0;

    .serviceFooterContainer {
      padding: 4px 0 4px 20px;

      img {
        height: 12px;
        vertical-align: middle;
        margin-right: 10px;
      }

      svg {
        height: 14px;
        vertical-align: middle;
      }
    }
  }
}

.bookCard {
  text-align: left;
}

.emergency.badge {
  font-weight: normal;
  font-size: 80%;
  background-color: $yellow;
  padding: 6px;
  display: inline-block;
  border-radius: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.MuiTextField-root {
  width: 100%;
}

.barthel {
  .iconClose-outer {
    position: absolute;
    top: 0;
    right: 12px;
    z-index: 1000;
    background-color: transparent;
  }

  .iconClose-outer .iconClose {
    position: relative;
    background-color: transparent;
    border: none;
    font-size: 8pt;
    font-family: $Raleway;
  }
}

.no-y-m {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.next-day-badge {
  font-size: 80%;
  background-color: $turqisa;
  color: $white;
  border-radius: 5px;
  text-align: center;
}

.cancellation-policy {
  margin-top: -20px;
  margin-bottom: 14px;
}

.dotted-items-table {
  border: 1px solid #666;
  max-width: 250px;
  margin: auto;
  padding: 12px;
  font-size: 90%;

  div.first {
    float: left;
    width: 75%;
  }

  div.last {
    float: right;
    padding-top: 10px;
  }

  > div {
    clear: both;
  }
}

.modal-button {
  border-radius: 20px;
  font-weight: 700;
}

.post-service-report {
  .check-container {
    display: flex;
    align-items: center;

    > div {
      font-size: 95%;
    }
  }

  .area {
    font-weight: bold;
    border-bottom: 1px solid #666;
  }
}

.css-1pyb950-MuiButtonBase-root-MuiButton-root:hover {
  background-color: $turqisa !important;
  box-shadow: none !important;
}

.EMERGENZA,
.FAMILIARE {
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $black;
  }
}

.editorial {
  .boxGiallo {
    background: $sand;
    border-radius: 20px 20px 0 0;
    margin-top: 10px;
    /*margin-bottom:10px;*/
    margin-bottom: 20px;
    box-shadow: 0 20px 10px -10px $silverGray !important;
    padding: 20px 10px 50px;
  }

  .header {
    > div {
      width: 100%;
      text-align: center;
      position: relative;
      font-family: $Playfair !important;
      font-size: 22px;
      padding-top: 15px;
      border-radius: 20px 20px 0 0;
      background-color: #b1d8e7;
    }

    img,
    svg,
    svg {
      z-index: -1;
      position: relative;
      margin-bottom: -20px;
    }
  }

  .description {
    min-height: 60px;
    width: auto;
    margin-top: -16%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;

    .title {
      text-align: center;
      font-size: 24px;
      font-family: $Playfair !important;
    }

    p {
      line-height: 24px;
    }
  }

  .description2 {
    border-radius: 0;
    width: auto;
    background-color: $white;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;

    .title {
      text-align: center;
      font-size: 24px;
      font-family: $Playfair !important;
    }

    p {
      line-height: 24px;
    }
  }

  .contenuto {
    padding-right: 20px;
    padding-left: 20px;

    .title {
      text-align: center;
      font-size: 24px;
      font-family: $Playfair !important;
    }

    p {
      line-height: 24px;
    }
  }
}

.userpage {
  .header {
    position: relative;
    z-index: -1;
    border-radius: 20px;
    overflow: hidden;
    max-height: 280px;

    img,
    svg,
    svg {
      width: 100%;
      position: relative;
      top: -35px;
    }
  }
}

.userpage .editorial .description > span {
  text-align: center;
  width: 100%;
  display: block;
}

.userpage .boxBianco {
  padding: 0;
}

.condizioni-container {
  background-color: $white;
  padding: 30px 0;
  font-family: $Playfair !important;

  .folder {
    margin: 10px 0;
    padding: 10px;

    .title {
      box-shadow: 5px -1px 15px $silverGray;
      background-color: $white;
      font-size: 24px;
      width: fit-content;
      border-radius: 20px 20px 0 0;
      padding: 5px 20px;
      margin-bottom: 0;
    }

    .title-right {
      box-shadow: -5px -1px 15px $silverGray;
      background-color: $white;
      font-size: 24px;
      width: fit-content;
      border-radius: 20px 20px 0 0;
      padding: 5px 30px;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: 0;
    }

    .body {
      box-shadow: 0 5px 15px $silverGray;
      background-color: $white;
      margin-top: 0;
      width: auto;
      border-radius: 0 20px 0 0;
      padding: 20px;
    }

    .body-right {
      box-shadow: 0 5px 15px $silverGray;
      background-color: $white;
      margin-top: 0;
      width: auto;
      border-radius: 20px 0 0 0;
      padding: 20px;
    }
  }
}

.notification {
  padding: 10px 4px 10px 4px;
  border-radius: 10px 10px 0 0;
  box-shadow: 4px 4px 10px $silverGray !important;
  background: $white;
  margin-bottom: 10px;
}

@media print {
  #footer {
    display: none;
  }
}

.linguetta {
  span {
    position: absolute;
    bottom: 0;
    display: block;
    padding-bottom: 4px;
    text-align: center;
    width: 100%;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 140px;
  background-color: orange;
  font-size: 10px;
  color: $white;
  transform-origin: 0 0;
  /*transform: translate(-85px, -16px) rotate(-45deg) translate(-20px, 0);*/
  transform: translate(-95px, -16px) rotate(-45deg) translate(-13px, 0);
  border-bottom: 2px dotted $white;
}

#accountMenu {
  .linguetta {
    width: 160px;
    /* old transform: translate(-85px, -10px) rotate(-45deg) translate(-25px, 5px); */
    transform: translate(-105px, -10px) rotate(-45deg) translate(-15px, 5px);
  }
}

@media screen and (min-width: 600px) {
  .css-1t29gy6-MuiToolbar-root {
    min-height: unset;
  }
  .linguetta {
    span {
      position: absolute;
      bottom: 0;
      display: block;
      padding-bottom: 4px;
      text-align: center;
      width: 100%;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 140px;
    height: 140px;
    background-color: orange;
    font-size: 10px;
    color: $white;
    transform-origin: 0 0;
    /* old transform: translate(-85px, -22px) rotate(-45deg) translate(-30px, 10px);*/
    transform: translate(-105px, -22px) rotate(-45deg) translate(-10px, 10px);
    border-bottom: 2px dotted $white;
  }
}

.cbLabel {
  a {
    color: $white;
  }
}

.servclienti {
  text-align: right;
  font-weight: 300;
  font-size: 0.8rem;

  .text {
    padding-right: 80px;
  }

  .number {
    margin-top: 3px;
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 4px;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1rem;
    white-space: nowrap;
    font-family: $Oswald;
  }

  img,
  svg {
    right: 0;
  }

  .icoContainer {
    width: 36px;
    display: inline-block;
    position: relative;

    img,
    svg {
      bottom: 0;
      width: 36px;
      position: absolute;
    }
  }
}

/* disable needle */
.service-not-used {
  opacity: 0.4;
}

.inputPassword {
  .MuiInputBase-formControl {
    background-color: $white;
  }
}

.servizio-clienti-container {
  background-color: $sand;
  padding: 10px;
  border-radius: 20px 20px 0 0;
  margin: 15px 0;
  box-shadow: 2px 2px 8px $silverGray !important;

  div {
    display: flex;
    justify-content: center;
  }

  p {
    &:first-child {
      margin-bottom: -10px;
    }

    font-size: large;
    font-weight: bold;
    text-align: center;
  }

  .servizio-clienti-button {
    margin-bottom: 10px;
  }
}

.servizio-cliente-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .servizio-clienti-checkbox {
    margin-left: 30px;

    button {
      width: 100%;
    }

    input {
      border: 3px solid $midGrey;
      box-shadow: 2px 2px 8px $midGrey !important;
    }

    a {
      color: $black !important;
    }
  }

  .section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;

    .subsection {
      padding: 2px;
      margin: 0 10px 10px 10px;
      width: 100%;
      border: 1px solid $midGrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      box-shadow: 2px 2px 8px $midGrey !important;

      label {
        font-family: $Raleway;
        text-transform: uppercase;
        font-size: smaller;
        font-weight: normal;
        margin-bottom: 5px;
      }

      input,
      textarea {
        font-family: $Raleway;

        &::placeholder {
          color: $black;
          font-weight: bold;
        }

        padding: 0 !important;
        border: $white !important;
      }
    }
  }
}

.svg-style {
  width: 160px;
  height: 60px;
}

a.asLink,
a.asLink:visited {
  color: $black;
}

.c-EMERGENZA {
  color: $emergenza !important;
}

.service-EMERGENZA {
  background-color: $emergenza-light;
  border-radius: 20px 20px 0 0;
  padding: 5px 10px 10px 10px;

  .MuiListItem-root,
  .MuiListItem-gutters {
    background-color: transparent !important;
  }
}

.button-EMERGENZA {
  background-color: $emergenza !important;
  color: $black !important;
}

.c-DOMICILIARE {
  color: $domiciliare !important;
}

.service-DOMICILIARE {
  background-color: $domiciliare-light;
  border-radius: 20px 20px 0 0;
  padding: 5px 10px 10px 10px;

  .MuiListItem-root,
  .MuiListItem-gutters {
    background-color: transparent !important;
  }
}

.button-DOMICILIARE {
  background-color: $domiciliare !important;
  color: $black !important;
}

.c-FAMILIARE {
  color: $familiare !important;
}

.service-FAMILIARE {
  background-color: $familiare-light;
  border-radius: 20px 20px 0 0;
  padding: 5px 10px 10px 10px;

  .MuiListItem-root,
  .MuiListItem-gutters {
    background-color: transparent !important;
  }
}

.button-FAMILIARE {
  background-color: $familiare !important;
  color: $black !important;
}

.login-box-menu-dx {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.faq-box .answer svg {
  width: 50%;
  height: 50%;
}

.appointment-card {
  margin: 25px 0;
  border: 1px solid $midGrey;
  border-radius: 20px !important;
  box-shadow: 2px 2px 8px $midGrey !important;

  .event-modal-state.can-accept {
    .serviceFooter {
      border-radius: 0 0 20px 20px !important;
    }
  }
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  box-shadow: unset !important;
}

.boxCondizioni {
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
  font-size: 14px;
}

#red-banner {
  background-color: $red;
  color: $white;
  width: 100%;
  font-family: $Raleway;
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.resetPasswordBox .css-sbdl93 {
  border-radius: 0 0 0 30px;
}

.pageChangePassword {
  .logo {
    width: auto;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 10px 15% 0;
  }
}

.triangleContainer {
  margin: 10px 0 0 20px;
}

.stats-modal {
  line-height: normal;
  letter-spacing: -0.025em;

  label {
    font-size: small;
  }

  .MuiBox-root {
    .iconClose-outer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      right: 0px;
      margin-bottom: -40px;
    }
  }

  .stats-container {
    padding: 0 0 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .stats-left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .MuiBox-root {
        margin-right: 10px;
      }
    }

    .stats-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.success-page {
  .logo {
    text-align: center;

    img,
    svg {
      margin-left: 20px;
    }
  }

  .first {
    margin-top: -5px !important;
    font-weight: bold;
  }

  .codice-prenotazione {
    margin-top: 5px;

    .large-icon {
      margin-top: -5px !important;
    }
  }

  .da-sapere {
    .label {
      background-color: $green;
      color: $white !important;
    }

    div > span {
      background-color: $green;
      color: $white !important;
    }
  }

  .bandaGialla {
    background-color: $white;
    line-height: 0;

    .text-center {
      text-align: justify;
    }
  }
}

.MuiBackdrop-root {
  opacity: 0.5 !important;
}

.box-caregivers {
  margin: 20px;
  padding: 5px;
  border-radius: 20px;

  .big-text {
    font-size: 2em;
    margin: -10px 0;
  }
}

.footer-banner {
  width: auto !important;
  border-radius: 25px 25px 0 0;
  margin: 0 5px;
  padding: 0 0 10px 0;

  .box-information {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border: 2px solid $white !important;
  }
}

.appuntamento-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;

  .buttonLink {
    top: unset;
  }
}

.appuntamento-box-vertical {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  row-gap: 10px;
  align-items: stretch;

  .buttonLink {
    top: unset;
    padding: 0 10px !important;
    width: 100%;
  }
}

.button-red {
  font-weight: bold;
  padding: 10px;
  border: 1px solid $red;
  border-radius: 20px;
  margin: 0 10px;
  background-color: $red;
  color: $white;
}

.button-green {
  font-weight: bold;
  padding: 10px;
  border: 1px solid $green;
  border-radius: 20px;
  margin: 0 10px;
  background-color: $green;
  color: $white;
}

.modal-are-you-sure {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 80%;
  background-color: $orange;
  border: 0;
  padding: 5px;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 349px) {
  .xs-column {
    flex-direction: column !important;
  }
}

@media (min-width: 600px) {
  .css-1t29gy6-MuiToolbar-root {
    min-height: 56px !important;
  }
}

.alert {
  border-radius: 4px;
  padding: 4px;
  text-align: center;
}

.alert.smalltext {
  font-size: 12px;
}

.alert.alert-danger {
  background-color: #a63030;
  color: white;
  margin-bottom: 8px;
}

.full-rounded {
  border-radius: 27px !important;
  padding: 15px 15px !important;
}

.bm-burger-button {
  z-index: 0 !important;
}

.underline {
  text-decoration: underline !important;
}

.MuiAccordionSummary-content {
  max-width: 90% !important;
}

.border-silverGray-lg {
  border: 1px solid $silverGray !important;
  border-radius: 10px !important;
}

.border-silverGray-t-lg {
  border: 1px solid $silverGray !important;
  border-radius: 10px 10px 0 0 !important;
}

.silverGray-shadow-sm {
  box-shadow: 0 0 5px 0 $silverGray !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0 !important;
}


.MuiAutocomplete-root {
  background-color: white;
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }
}

.cap_group_title {
  font-weight: bold;
  color: black;
  width: 100%;
  display: block;
}

.cap_children {
  display: block;
  width: 100%;
  text-align: center;
}

/*
.cap_children li * {
  list-style: none;
  padding: 0 !important;
  text-align: center !important;
  display: block;
  width: 100%;
  color: black;
  border: 1px solid blue;
}
*/
.cap_opt_container {
  display: block;
  width: 97%;
  padding-left: 2% !important;
  clear: both;
}

.cap_opt_container div {
  border: 1px solid #ccc !important;
  display: inline;
  width: 80px;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
}

/*
.service_report_view .r-title { 
  display: none 
}
*/