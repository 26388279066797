/*forced style for service svg, dangerous html not processing style tag*/
#aiuto {
  .cls-1 {
    fill: #e6b5a8;
  }

  .cls-2 {
    fill: #946a57;
  }

  .cls-3 {
    fill: #d88865;
  }

  .cls-4 {
    fill: #c59d7b;
  }

  .cls-5 {
    fill: #dddce2;
  }

  .cls-6 {
    fill: #d9ba82;
  }

  .cls-7 {
    fill: #dec29f;
  }

  .cls-8 {
    fill: #8a5b4a;
  }

  .cls-9 {
    fill: #5a659c;
  }

  .cls-10 {
    fill: #482d23;
  }

  .cls-11 {
    fill: #3d3d3d;
  }

  .cls-12 {
    fill: #2e3544;
  }

  .cls-13 {
    fill: #585568;
  }

  .cls-14 {
    fill: #3c3c3e;
  }
}

#assistenza {
  .cls-1 {
    fill: #040707;
  }

  .cls-2 {
    fill: #050808;
  }

  .cls-3 {
    fill: #ebeef3;
  }

  .cls-4 {
    fill: #fefefe;
  }

  .cls-5 {
    fill: #987164;
  }

  .cls-6 {
    fill: #97a5bf;
  }

  .cls-7 {
    fill: #7f8db0;
  }

  .cls-8 {
    fill: #91caec;
  }

  .cls-9 {
    fill: #9f5a3e;
  }

  .cls-10 {
    fill: #bc605f;
  }

  .cls-11 {
    fill: #cf6e6b;
  }

  .cls-12 {
    fill: #bcc3d5;
  }

  .cls-13 {
    fill: #bebec9;
  }

  .cls-14 {
    fill: #cb9584;
  }

  .cls-15 {
    fill: #d23141;
  }

  .cls-16 {
    fill: #d7a899;
  }

  .cls-17 {
    fill: #aa2b38;
  }

  .cls-18 {
    fill: #161b25;
  }

  .cls-19 {
    fill: #131822;
  }

  .cls-20 {
    fill: #0b0f10;
  }

  .cls-21 {
    fill: #090c0d;
  }

  .cls-22 {
    fill: #080a0a;
  }

  .cls-23 {
    fill: #070a0b;
  }

  .cls-24 {
    fill: #1d1e1e;
  }

  .cls-25 {
    fill: #17a3a4;
  }

  .cls-26 {
    fill: #3d5da3;
  }

  .cls-27 {
    fill: #808eb0;
  }

  .cls-28 {
    fill: #5b453e;
  }

  .cls-29 {
    fill: #2b3f84;
  }

  .cls-30 {
    fill: #293d84;
  }

  .cls-31 {
    fill: #293d83;
  }

  .cls-32 {
    fill: #574741;
  }
}

#alzheimer {
  .cls-1 {
    fill: #8f93cd;
  }

  .cls-2 {
    fill: #ce9db8;
  }

  .cls-3 {
    fill: #dcdbe1;
  }

  .cls-4 {
    fill: #dcdbe0;
  }

  .cls-5 {
    fill: #d8988d;
  }

  .cls-6 {
    fill: #dc956e;
  }

  .cls-7 {
    fill: #e2b18f;
  }

  .cls-8 {
    fill: #e2b18e;
  }

  .cls-9 {
    fill: #282026;
  }

  .cls-10 {
    fill: #7b5d47;
  }

  .cls-11 {
    fill: #515151;
  }
}

#infermieri {
  .cls-1 {
    fill: #f7efe5;
  }

  .cls-2 {
    fill: #f9ecec;
  }

  .cls-3 {
    fill: #f8ebeb;
  }

  .cls-4 {
    fill: #f8eee6;
  }

  .cls-5 {
    fill: #683c11;
  }

  .cls-6 {
    fill: #ec9791;
  }

  .cls-7 {
    fill: #ebcab1;
  }

  .cls-8 {
    fill: #fff;
  }

  .cls-9 {
    fill: #f4e8db;
  }

  .cls-10 {
    fill: #edd0ba;
  }

  .cls-11 {
    fill: #fefefe;
  }

  .cls-12 {
    fill: #fefdfd;
  }

  .cls-13 {
    fill: #e4675f;
  }

  .cls-14 {
    fill: #e4665f;
  }

  .cls-15 {
    fill: #969696;
  }

  .cls-16 {
    fill: #8b8b8b;
  }

  .cls-17 {
    fill: #9e8884;
  }

  .cls-18 {
    fill: #85604e;
  }

  .cls-19 {
    fill: #8a8a8a;
  }

  .cls-20 {
    fill: #8f8f8f;
  }

  .cls-21 {
    fill: #b6e2e2;
  }

  .cls-22 {
    fill: #936955;
  }

  .cls-23 {
    fill: #b2dfdf;
  }

  .cls-24 {
    fill: #aededf;
  }

  .cls-25 {
    fill: #dbcdc6;
  }

  .cls-26 {
    fill: #d9cac2;
  }

  .cls-27 {
    fill: #e2b28f;
  }

  .cls-28 {
    fill: #e2b18f;
  }

  .cls-29 {
    fill: #e2b18e;
  }

  .cls-30 {
    fill: #435ba2;
  }

  .cls-31 {
    fill: #1aa3a4;
  }

  .cls-32 {
    fill: #3cb8b8;
  }

  .cls-33 {
    fill: #19a3a4;
  }

  .cls-34 {
    fill: #898a8a;
  }

  .cls-35 {
    fill: #898989;
  }

  .cls-36 {
    fill: #2f9c9d;
  }

  .cls-37 {
    fill: #3c58a5;
  }

  .cls-38 {
    fill: #61c0c1;
  }

  .cls-39 {
    fill: #4bb1b1;
  }

  .cls-40 {
    fill: #7c5b4a;
  }
}

#riabilitativi {
  .cls-1 {
    fill: #e6b695;
  }

  .cls-2 {
    fill: #e3b494;
  }

  .cls-3 {
    fill: #6c6c6c;
  }

  .cls-4 {
    fill: #97abd0;
  }

  .cls-5 {
    fill: #97aacf;
  }

  .cls-6 {
    fill: #cea0b8;
  }

  .cls-7 {
    fill: #ae8e75;
  }

  .cls-8 {
    fill: #b09077;
  }

  .cls-9 {
    fill: #cda0b8;
  }

  .cls-10 {
    fill: #db9671;
  }

  .cls-11 {
    fill: #cb9eb5;
  }

  .cls-12 {
    fill: #d8cabb;
  }

  .cls-13 {
    fill: #af8f76;
  }

  .cls-14 {
    fill: #af8e76;
  }

  .cls-15 {
    fill: #c6c6c6;
  }

  .cls-16 {
    fill: #e2b190;
  }

  .cls-17 {
    fill: #e2b191;
  }

  .cls-18 {
    fill: #e1b394;
  }

  .cls-19 {
    fill: #424242;
  }

  .cls-20 {
    fill: #444;
  }

  .cls-21 {
    fill: #3e3e3e;
  }

  .cls-22 {
    fill: #3f3f3f;
  }

  .cls-23 {
    fill: #94a6c8;
  }

  .cls-24 {
    fill: #95633e;
  }
}

#supporto {
  .cls-1 {
    fill: #fefefe;
  }

  .cls-2 {
    fill: #98a6c0;
  }

  .cls-3 {
    fill: #d6a899;
  }

  .cls-4 {
    fill: #e30613;
  }

  .cls-5 {
    fill: #058488;
  }

  .cls-6 {
    fill: #141923;
  }

  .cls-7 {
    fill: #3d5da3;
  }

  .cls-8 {
    fill: #808eb0;
  }

  .cls-9 {
    fill: #808eb1;
  }
}
