.accountMenu.logged:after {
  content: 'TU';
}

.accountMenu.jollyLogged:after {
  content: 'JOLLY';
  color: red;
}

.accountMenu.jollyLogged {
  color: red;
}
