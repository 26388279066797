.chat-button {
  width: fit-content;
  align-self: flex-end;
  border-radius: 9999px;
  background-color: #00aace;
  border-color: #00aace;
  box-shadow: 2px 2px 5px 2px #000000;
  padding: 2px;
}

.bubble {
  margin: 0 0 15px 0;
  width: fit-content;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-color: #00aace;
  border-radius: 20px;
  padding: 10px;
  height: fit-content;
  gap: 10px;
  textarea {
    border: none;
    background-color: #00aace;
    color: white;
    font-size: 1.2em;
    padding: 0 10px;
    width: 90%;

    &::placeholder {
      color: white;
    }
  }
}

.bubble::after {
  content: '';
  position: absolute;
  align-self: flex-end;
  right: 35px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #00aace;
  border-bottom: 0;
  border-right: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

#chat-container {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: fit-content;
  z-index: 900;
  border-radius: 20px;
  padding: 10px;
  height: fit-content;
  width: 90%;
  overflow: auto;
  max-height: 300px;
}

.send-button {
  width: fit-content;
  height: fit-content;
  align-self: center;
  border-radius: 9999px;
  background-color: white;
  border-color: white;

  padding: 2px;
}
