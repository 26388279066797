/* path color */
$emergenza: #ffcc00;
$emergenza-light: #fff0b2;
$domiciliare: #a3cafe;
$domiciliare-light: #e0efff;
$familiare: #bebebe;
$familiare-light: #ebebeb;

/* modal color */
$late: #ffe371;
$to-accept: rgb(255, 227, 113);
$refused: #ff0000ff;
$ended: #00e600;
$started: #a2e7f6;
/* box color */
$boxError: #ffe6e6;
$boxSuccess: #ebfaeb;
/* button color */
$btngreybg: #333333;
$btngreenbg: #48d700;
$btnbluebg: #0082b1;
/* comma color */
$commaOpen: #0099cc;
$commaClosed: #ffcc00;
/* utils color */
$bgBlue: #3681b1;
$linkblue: #08359a;
$bgAzure: #b0e0e6;

/* color */
$basaltGray: #9a9a9a;
$black: #000000;
$brown-light: #d2d1c1;
$bluishGray: #373a47;
$celeste: #c2e0ff;
$cyan: #13a8c9;
$darkGrey: #797979;
$goldishGray: #b8b7ad;
$gray: #808080ff;
$lightGrey: #eee;
$midGrey: #e3e3e3;
$red: #ff0000ff;
$redStrong: #cb0214;
$sand: #ffe371;
$turqisa: #0082b1;
$silverGray: #ccc;
$white: #fff;
$yellow: #f9e46f;
$green: #48d700;
$sunset-orange: #ffd24c;
$orange: #f09400;
$gray-message: #d8d8d8;
