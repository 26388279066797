@import 'colors';
@import 'fonts';
@import 'services';
@import 'variables';

// text styles
.textCenter {
  text-align: center !important;
}

.textRight {
  text-align: right !important;
}

.textLeft {
  text-align: left !important;
}

.lighter {
  font-weight: lighter;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline !important;
}

.normalWeight {
  font-weight: normal;
}

.playFair {
  font-family: $Playfair !important;
}

.Raleway {
  font-family: $Raleway !important;
}

.uppercase {
  text-transform: uppercase;
}

.overflow-x-auto {
  overflow-x: auto;
}

// functionality

.w-fit {
  width: fit-content;
}

.clickable,
.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block !important;
}

.unset {
  position: unset !important;
}

.w-max {
  width: max-content !important;
}

.w-full {
  width: 100% !important;
}

.border-lg {
  border-radius: 10px !important;
}

.border-b-md {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.border-full-lg {
  border-radius: 20px !important;
}

// flexbox

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.around {
  justify-content: space-around !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-around {
  justify-content: space-around !important;
}

.space-between {
  justify-content: space-between !important;
}

.items-center {
  align-items: center;
}

.-mb-10 {
  margin-bottom: -10px !important;
}

// 🤖🤖🤖  Generatore di classi design system  🤖🤖🤖
// generatore di classi margin e padding
@each $property in $properties {
  @each $direction in $directions {
    @for $size from 0 through 100 {
      @if ($direction == z) {
        .#{str-slice($property, 0, 1)}-#{$size} {
          #{$property}: #{$size}px !important;
        }
      } @else {
        .#{str-slice($property, 0, 1)}#{str-slice($direction, 0, 1)}-#{$size} {
          @if ($direction == x) {
            #{$property}-left: #{$size}px !important;
            #{$property}-right: #{$size}px !important;
          } @else if ($direction == y) {
            #{$property}-top: #{$size}px !important;
            #{$property}-bottom: #{$size}px !important;
          } @else {
            #{$property}-#{$direction}: #{$size}px !important;
          }
        }
      }
    }
  }
}

// generatore di classi size, width, height, gap
@for $size from 0 through 100 {
  .font-size-#{$size} {
    font-size: #{$size}px !important;
  }

  .w-#{size} {
    width: #{$size}px !important;
  }
  .h-#{$size} {
    height: #{$size}px !important;
  }

  .gap-#{$size} {
    column-gap: #{$size}px !important;
  }
}

// generatore di classi colori
@each $value in $colors-var {
  .bg-#{nth($value, 1)} {
    background-color: nth($value, 2) !important;
  }
  .text-#{nth($value, 1)},
  .#{nth($value, 1)} {
    color: nth($value, 2) !important;
  }
  .border-#{nth($value, 1)} {
    border-color: nth($value, 2) !important;
  }
}
