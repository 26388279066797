@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;600;700&family=Playfair+Display:wght@300;400;500;700&family=Oswald:wght@700&display=swap');

$Raleway: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  cursive;
$Playfair: 'Playfair Display', 'Raleway', Helvetica, serif;
$Roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$generic-font-family-2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
$Oswald: 'Oswald', sans-serif;
