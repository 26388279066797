@import 'src/styles/colors.scss';

.home-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  background-color: $brown-light;
  width: 86%;
  border: 0;
  align-content: center;
  justify-content: center;
  align-items: center;
  outline: none;
}
