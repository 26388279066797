.bm-item-list {
  font-size: small !important;
}

@media screen and (max-width: 300px) {
  .burgerMenu .bm-item.menuTop .menuPages {
    margin-top: 10px;
  }
  .burgerMenu .bm-item.menuBottom {
    margin-top: 10px;
  }
}
