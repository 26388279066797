#root > div > div > div.editorial.PRIVACY > div.boxGiallo.contenuto > p {
  overflow-x: auto;
  width: 90%;
}

/*
  bugid#8963
#root > div > div > div.editorial.PRIVACY > div.boxGiallo.contenuto {
  width: 80%;
}
*/
